export const auth0Config = {
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN
};

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
};

export const cognitoConfig = {
  poolData: {
      region: 'ap-southeast-2',
      userPoolId: 'ap-southeast-2_0T0c2cjGe',
      userPoolWebClientId: '6h7kddlpj6iomcj7mmu757751e',
      authenticationFlowType: 'USER_PASSWORD_AUTH',
      identityPoolId: 'ap-southeast-2:6b4c2c24-bf00-42f4-8bc3-ba1931e98f53'
  },
  APIEndpoint: 'https://oon5we1qjl.execute-api.ap-southeast-2.amazonaws.com/dev',
  bucket: 'my-pool-party-0000',
  region: 'ap-southeast-2'
}